.feature {
    display: inline-flex;
}

.feature-grid {
    position: relative;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    z-index: 1;

    @media screen and #{$media_sm} {
        flex-direction: column;
    }
}

.feature-1 {
    position: relative;
    flex: 0 0 33.3333333333%;
    justify-content: space-between;
    max-width: 33.3333333333%;
    padding: 50px 30px;
    overflow: hidden;
    color: #fff;
    transition: box-shadow .2s ease;

    @media screen and #{$media_lg} {
        flex-direction: column;
        align-items: center;
        padding: 40px 20px;
        text-align: center;
    }
    @media screen and #{$media_md} {
        padding: 20px;
    }
    @media screen and #{$media_sm} {
        flex: 0 0 100%;
        flex-direction: row;
        align-items: flex-start;
        max-width: 100%;
        padding: 20px 20px;
        text-align: left;
    }
    &:hover,
    &:focus {
        color: #fff;
        text-decoration: none;
    }

    &:nth-child(4n+1) {
        background-color: $color-main-1;
        box-shadow: inset 0 0 0 1px rgba($color-main-1, 0),
                    inset 0 0 0 1px rgba(#fff, 0);

        &:hover {
            box-shadow: inset 0 0 0 8px $color-main-1,
                        inset 0 0 0 10px #fff;
        }
    }
    &:nth-child(4n+2) {
        background-color: $color-main-2;
        box-shadow: inset 0 0 0 1px rgba($color-main-2, 0),
                    inset 0 0 0 1px rgba(#fff, 0);

        &:hover {
            box-shadow: inset 0 0 0 8px $color-main-2,
                        inset 0 0 0 10px #fff;
        }
    }
    &:nth-child(4n+3) {
        background-color: $color-main-3;

        box-shadow: inset 0 0 0 1px rgba($color-main-3, 0),
                    inset 0 0 0 1px rgba(#fff, 0);

        &:hover {
            box-shadow: inset 0 0 0 8px $color-main-3,
                        inset 0 0 0 10px #fff;
        }
    }

    .feature-title {
        display: block;
        margin-bottom: 10px;
        font-size: 1.4rem;
        font-weight: 500;
    }
    .feature-text {
        display: block;
        line-height: normal;

        @media screen and #{$media_lg} {
            margin-bottom: 15px;
        }
    }
}

.feature-2 {
    flex: 0 0 100%;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 10px 0 0;
    text-align: center;

    .feature-icon {
        display: inline-flex;
        align-items: center;
        height: 100px;
    }
    .feature-title {
        margin-bottom: 0;
        font-size: 1.5rem;
        font-weight: 300;
        color: rgba(255, 255, 255, .9);
    }
}

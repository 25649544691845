.specialist-item {
    display: block;
    color: #fff;

    &__title,
    &__text {
        display: block;
        margin-top: 10px;

        @media screen and #{$media_sm} {
            text-align: center;
        }
    }
    &__title {
        font-size: 1.3rem;
        line-height: normal;
    }
    &__text {
        font-weight: 300;
        color: rgba(255, 255, 255, .8);
    }

    &:hover,
    &:focus {
        color: #fff;
        text-decoration: none;
    }

    &__overlay {
        position: absolute;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        font-size: 3.2rem;
        color: #fff;
        z-index: 1;

        > span {
            opacity: 0;
            transition: transform .2s ease, opacity .3s ease;
            transform: scale(1.4);
        }
        &::before,
        &::after {
            content: "";
            position: absolute;
            border-radius: 100%;
        }
        &::before {
            top: -1px;
            right: -1px;
            bottom: -1px;
            left: -1px;
            background-color: $color-main-3;
            opacity: 0;
            transition: opacity .3s ease;
        }
        &::after {
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            box-shadow: inset 0 0 0 1px rgba($color-main-3, 0),
                        inset 0 0 0 1px rgba(#fff, 0);
            transition: box-shadow .2s ease;
            transition-delay: .15s;
            z-index: 1;
        }
    }

    &:hover .specialist-item__overlay {
        > span {
            opacity: 1;
            transform: scale(1);
        }
        &::before {
            opacity: .95;
        }
        &::after {
            box-shadow: inset 0 0 0 8px $color-main-3,
                        inset 0 0 0 10px #fff;
        }
    }

    &__img {
        position: relative;
        display: block;
        margin: 0 auto;
        width: 232px;
        max-width: 100%;
        border-radius: 100%;
        background-color: #fff;
        overflow: hidden;

        &::before {
            content: "";
            display: block;
            padding-top: 100%;
        }

        img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            font-family: "object-fit: cover";
            object-fit: cover;
        }
    }
}

.specialist-item-style-2 {
    display: flex;
    align-items: center;
    padding: 30px 60px;
    margin-left: -20px;
    margin-right: -20px;
    background-color: $color-main-3;

    @media screen and #{$media_sm} {
        flex-direction: column;
        align-items: center;
    }

    .specialist-item__img {
        min-width: 200px;
        margin-left: 0;
        margin-right: 30px;

        @media screen and #{$media_sm} {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

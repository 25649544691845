.info {
    padding: 30px 0 30px;
    background-color: #f5f5f5;

    &-item {
        display: inline-flex;
        align-items: center;

        a {
            &:not(.btn) {
                color: inherit;
                text-decoration: none;

                &:hover,
                &:focus {
                    color: inherit;
                    text-decoration: underline;
                }
            }
        }


        &:last-of-type {
            margin-right: 0;
        }

        &__icon {
            margin-right: 15px;
            font-size: 0;
            color: $color-main-1;

            > span {
                font-size: 1.7rem;
            }
        }
        &__content {
            display: block;
        }
        &__title {
            margin-top: -2px;
            font-weight: 500;
            color: $color_grey_2;
            line-height: normal;
        }
        &__subtitle {
            color: $color_grey_1;
            line-height: normal;
        }
    }
}

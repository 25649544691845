button {
    cursor: pointer;
}

/* Placeholders */
::placeholder,
.form-control::placeholder {
    color: inherit;
}

/* Remove outline from focused buttons */
button:focus {
    outline: 0;
}

/* From */
.form {
    .form-group {
        position: relative;
        margin-bottom: 0;
        overflow: hidden;

        &:not(.field) {
            &::before,
            &::after {
                display: none;
            }
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            left: 15px;
        }
        &::before {
            top: 39px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 23px;
            height: 23px;
            background-position: center;
            background-repeat: no-repeat;
            z-index: 3;
        }
        &::after {
            right: 15px;
            bottom: 0;
            height: 1px;
            border-bottom: 1px solid $color_main_3;
            opacity: 0;
            transition: transform .4s ease, opacity .2s ease;
            transform: scaleX(0);
            z-index: 1;
        }
        &:hover {
            &::after {
                opacity: 1;
                transform: scaleX(1);
            }
        }

        &.field-vase-ima::before {
            background-image: url("../images/icon-person.svg");
        }
        &.field-nomer-telefona::before {
            background-image: url("../images/icon-keypad.svg");
            background-size: cover;
        }
        &.field-e-mail::before {
            background-image: url("../images/icon-mail.svg");
        }
        &.field-data::before {
            background-image: url("../images/icon-date.svg");
        }
        &.field-vrema::before {
            background-image: url("../images/icon-time.svg");
            background-size: cover;
        }
        &.field-vase-soobsenie::before {
            background-image: url("../images/icon-chat.svg");
        }

    }
    &__control {
        position: relative;
        height: 45px;
        padding: 10px 0 10px 40px;
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid $color_grey_3;
        box-shadow: none;
        color: rgba($color_grey_2, .6);
        transition: border-color .3s ease, color .2s ease;
        transition-delay: .1s;

        &::placeholder {
            color: rgba($color_grey_2, .6);
            opacity: 1;
            transition: color .2s ease;
        }

        &:hover,
        &:focus {
            color: $color_grey_2;
            box-shadow: none;
            border-color: $color_main_3;

            &::placeholder {
                color: $color_grey_2;
            }
        }
        &:focus {
            border-color: $color_main_1;
            z-index: 2;
        }

    }
    textarea,
    textarea.form-control,
    textarea.form-control.form__control {
        min-height: 45px;
        height: 45px;
        max-height: 160px;

        &.form__control_2 {
            min-height: 48px;
            height: 164px;
            max-height: 190px;
        }
    }
    label {
        font-family: $font_headings;
        font-size: .9rem;
    }
    select.form-control:not([size]):not([multiple]) {
        height: 34px;
    }
    select.form-control {
        color: $color_body;
        cursor: pointer;

        &:focus {
            cursor: pointer;
        }

        option {
            color: $color_body;
        }
    }
    @-moz-document url-prefix() {
        select.form-control option {
            color: inherit
        }
    }
    select.form-control[multiple] option {
        color: inherit
    }
    /* fix for select on iOs devices */
    select.form-control {
        background-color: #fff;
    }

    // Description under form-control
    &-control-descrtiption {
        font-size: 0.8rem;
        margin-top: 7px;
        color: $color_grey_4;

        a {
            text-decoration: none;
        }
    }
    // Checkbox main
    &-check {
        display: block;
        margin: 6px 0;
        padding-left: 0;
    }
    &__check {
        cursor: pointer;
        position: relative;
        display: block;
        margin-bottom: 0;
        padding: 3px 0 4px 40px;
        color: $color_main_3;
        font-size: .6rem;
        font-weight: 400;
        letter-spacing: 0;
        vertical-align: middle;
        user-select: none;

        &-input {
            position: absolute;
            opacity: 0;
            cursor: pointer;

            ~ .form__check-checkmark {
                background-color: #fff;
                border: 1px solid $color_grey_2;
                transition: background-color .15s ease, border-color .15s ease;
            }

            &:checked ~ .form__check-checkmark {
                background-color: #fff;
                border-color: $color_grey_2;

                &::after,
                &::before {
                    display: block;
                }
            }
        }

        &-checkmark {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            height: 28px;
            width: 28px;
            border-radius: 0;
            background-color: #fff;

            &::after,
            &::before {
                content: '';
                position: absolute;
                display: none;
                transform: rotate(-45deg);
                transform-origin: 0% 100%;
            }
            &::after {
                top: 10px;
                left: 11px;
                width: 3px;
                height: 7px;
                border-top: solid $color_main_3;
                border-width: 7px;
                animation: check-one 0.6s linear;
            }
            @keyframes check-one {
                0% {border-width: 0px;}
                20% {border-width: 7px;}
                100% {border-width: 7px;}
            }
            &::before {
                top: 15px;
                left: 12px;
                width: 13px;
                height: 3px;
                border-left: solid $color_main_3;
                border-width: 13px;
                animation: check-two 0.6s linear;
            }
            @keyframes check-two {
                0% {border-width: 0px;}
                20% {border-width: 0px;}
                40% {border-width: 13px;}
                100% {border-width: 13px;}
            }
        }
    }

    // File
    .form__file {
        display: inline-block;
        position: relative;
        width: 84px;
        height: 72px;
        margin-right: 5px;
        margin-bottom: 10px;

        &:last-of-type {
            margin-right: 0;
        }

        &--input {
            visibility: hidden;
            width: 0;
            height: 0;
        }
        &--label {
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0;
            box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .2);
            border-radius: 3px;
            background-image: url(../../assets/images/add-img-default.png);
            background-repeat: no-repeat;
            background-position: center;
            overflow: hidden;
            transition: box-shadow .2s ease;

            &:hover,
            &:focus {
                box-shadow: inset 0 0 0 1px $color_main_1;
            }

            &::after {
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
        &--img {
            width: 100%;
            height: 100%;
        }
        &--remove {
            position: absolute;
            z-index: 1;
            width: 14px;
            height: 14px;
            top: 5px;
            right: 5px;
            border-radius: 7px;
            background-color: #fff;
            color: rgba(0, 0, 0, .8);
            text-decoration: none;
            transition: background-color .2s ease, color .2s ease;

            > .icon {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                left: 1px;
                top: 1px;
                width: calc(100% - 1px);
                height: calc(100% - 1px);
                font-size: 1rem;
            }

            &:hover,
            &:focus {
                background-color: $color_main_2;
                color: #fff;
                text-decoration: none;
            }
        }
    }
}

/* Form Style Feedback */
.form__feedback {
    .form__control {
        border: 1px solid $color_grey_2;
        border-radius: 2px;
        font-size: 0.75rem;
        transition: border-color .2s ease, box-shadow .2s ease;

        &:hover,
        &:focus {
            border-color: $color_main_1;
        }
        &:focus {
            box-shadow: 0 0 1px 1px rgba($color_main_1, .2);
        }

        &.active {
            border-color: $color_main_2;

            &:focus {
                box-shadow: 0 0 1px 1px rgba($color_main_2, .2);
            }
        }
    }
}

// Form Style Sign In
.form__signin {
    .form__control {
        height: 40px;
        padding: 10px 14px;
        border: 1px solid $color_grey_2;
        border-radius: 2px;
        font-size: 0.8rem;
        transition: border-color .2s ease, box-shadow .2s ease;

        &:hover,
        &:focus {
            border-color: $color_main_1;
        }
        &:focus {
            box-shadow: 0 0 1px 1px rgba($color_main_1, .2);
        }

        &.active {
            border-color: $color_main_2;

            &:focus {
                box-shadow: 0 0 1px 1px rgba($color_main_2, .2);
            }
        }
    }
    &--btn {
        width: 280px;
        padding: 6px 20px;
        font-size: 1.2rem;
    }
    &--link {
        text-decoration: none;
        font-size: 0.9rem;

        &:hover {
            text-decoration: underline;
        }
    }
    .form-check {
        .form__check {
            padding-left: 24px;
            font-size: 0.73rem;
            font-weight: 400;
            color: #000;

            &-checkmark {
                top: 2px;
            }
        }
    }
}

// From Style Ad
.form__ad {
    .form__control {
        height: 36px;
        padding: 8px 14px;
        border: 1px solid rgba(0, 0, 0, .2);
        border-radius: 3px;
        font-size: 0.8rem;
        transition: border-color .2s ease, box-shadow .2s ease;

        &:hover,
        &:focus {
            border-color: $color_main_1;
        }
        &:focus {
            box-shadow: 0 0 1px 1px rgba($color_main_1, .2);
        }

        &.active {
            border-color: $color_main_2;

            &:focus {
                box-shadow: 0 0 1px 1px rgba($color_main_2, .2);
            }
        }
    }
    &--btn {
        width: 280px;
        padding: 6px 20px;
        font-size: 1.2rem;
    }
    &--link {
        text-decoration: none;
        font-size: 0.9rem;

        &:hover {
            text-decoration: underline;
        }
    }
    .form-check {
        .form__check {
            padding-left: 24px;
            font-size: 0.73rem;
            font-weight: 400;
            color: #000;

            &-checkmark {
                top: 2px;
            }
        }
    }
}

.slider {
    .swiper-slide {
        height: auto;
    }

    .swiper-button-next,
    .swiper-button-prev {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 82px;
        height: 82px;
        margin-top: -41px;
        font-size: 2rem;
        color: #fff;
        background: none;
        background-color: $color-main-1;
        box-shadow: inset 0 0 0 1px rgba($color-main-1, 0),
                    inset 0 0 0 1px rgba(#fff, 0);
        transition: background-color .2s ease, box-shadow .2s ease;

        &:hover {
            box-shadow: inset 0 0 0 5px $color-main-1,
                        inset 0 0 0 7px #fff;
        }

        @media screen and #{$media_sm} {
            width: 60px;
            height: 60px;
            top: 150px;
            margin-top: 0;
        }
    }
    .swiper-button-next {
        right: 0;
    }
    .swiper-button-prev {
        left: 0;
    }
}

.slider-arrows-clone {
    @media screen and #{$media_md} {
        padding-top: 60px;
    }
    .swiper-button-next,
    .swiper-button-prev {
        visibility: hidden;
        opacity: 0;
        z-index: -1;
    }

    + .slider-buttons {
        position: absolute;
        top: 55px;
        left: 0;
        width: 100%;
        height: 0;
        z-index: 0;

        @media screen and #{$media_md} {
            top: 120px;
        }

        .container {
            display: flex;
            justify-content: flex-end;
            height: 0;
        }

        .swiper-button-next,
        .swiper-button-prev {
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            top: auto;
            right: auto;
            bottom: auto;
            left: auto;
            width: 60px;
            height: 60px;
            margin-top: 0;
            background: none;
            border-radius: 100%;
            color: $color-main-1;
            font-size: 1.5rem;
            transition: box-shadow .3s ease;
            z-index: 1;

            &::before,
            &::after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border-radius: 100%;
            }
            &::before {
                box-shadow: inset 0 0 0 1px $color-grey-3;
                transition: transform .2s ease, box-shadow .2s ease;
                transform: scale(1);
            }
            &::after {
                box-shadow: inset 0 0 0 4px $color-main-3;
                opacity: 0;
                transition: transform .2s ease, opacity .25s ease;
                transition-delay: .2s;
                transform: scale(1.2);
            }
            &:hover {
                &::before {
                    transform: scale(.8);
                }
                &::after {
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }
        .swiper-button-prev {
            margin-right: 20px;
        }
    }
}

.slider-specialists {
    + .slider-buttons {
        .swiper-button-next,
        .swiper-button-prev {
            color: #fff;

            &::before {
                box-shadow: inset 0 0 0 1px $color-grey-3;
            }
            &::after {
                box-shadow: inset 0 0 0 4px #fff;
            }
        }
    }
}

.slider-main {
    .swiper-wrapper {
        align-items: stretch;

        .swiper-slide {
            padding: 90px 0 180px;

            .container {
                position: relative;
            }

            .slider-title {
                margin-bottom: 20px;
                line-height: normal;
            }
            .slider-text,
            .slider-phone {
                font-size: 1.4rem;
            }
            .slider-phone {
                margin-top: 10px;
                font-weight: 500;

                a {
                    color: inherit;

                    &:hover,
                    &:focus {
                        color: inherit;
                    }
                }
            }
        }
    }
}

.slider-reviews {
    .swiper-slide {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        a {
            height: 100%;
        }

        img {
            max-width: 100%;
        }
    }
}

.slider-docs {
    .swiper-slide {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        a {
            height: 100%;
        }

        img {
            max-width: 100%;
        }
    }
}

.slider-gallery {
    padding-bottom: 60px;

    .swiper-pagination {
        bottom: 0;

        .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            background-color: $color-main-1;
            opacity: .4;
            transition: opacity .2s ease;

            &.swiper-pagination-bullet-active {
                opacity: 1;
            }
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
        top: 50%;
        margin-top: -60px;

        @media screen and #{$media_sm} {
            margin-top: -60px;
        }
    }

    .swiper-slide {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        .container,
        .row {
            height: 100%;
        }

        a {
            height: 100%;
        }

        img {
            max-width: 100%;
        }
    }
}

.box,
.box-1 {
    position: relative;
    display: block;
    z-index: 1;
}

.box-1 {
    padding: 55px 0;
}
.box-2 {
    padding: 20px 0;
}

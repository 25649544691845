.info-block {
    display: flex;
    align-items: stretch;

    @media screen and #{$media_md} {
        flex-wrap: wrap;
    }

    > div {
        flex: 0 0 50%;
        max-width: 50%;

        @media screen and #{$media_md} {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    &__img {
        height: 100%;
        max-height: 320px;
        overflow: hidden;

        img {
            width: 100%;
            height: 320px;
            // stylelint-disable-next-line
            font-family: "object-fit: cover";
            object-fit: cover;

            @media screen and #{$media_lg} {
                height: 280px;
            }
            @media screen and #{$media_md} {
                height: 220px;
            }
            @media screen and #{$media_md} {
                height: 200px;
            }
        }
    }

    &__text {
        position: relative;
        display: flex;
        align-items: center;
        max-height: 320px;
        padding: 25px 40px;
        font-size: 1.2rem;
        font-weight: 300;

        @media screen and #{$media_lg} {
            max-height: 280px;
            padding: 25px 30px;
            font-size: .9rem;
        }

        &::after,
        &::before {
            content: "";
        }

        > div {
            display: flex;
            flex-direction: column;
            max-height: 100%;
            overflow: hidden;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    // Style 1
    &-style-1 {
        .info-block__text {
            @media screen and #{$media_md} {
                font-size: 1rem;
            }
            @media screen and #{$media_sm} {
                max-height: 300px;
                font-size: .9rem;
            }

            &::before,
            &::after {
                position: absolute;
                right: 100px;
                left: 40px;
                height: 6px;
                background-color: $color_main_1;

                @media screen and #{$media_md} {
                    top: 0;
                    right: auto;
                    bottom: 0;
                    left: auto;
                    width: 4px;
                    height: auto;
                }
            }
            &::before {
                top: 0;

                @media screen and #{$media_md} {
                    left: 0;
                }
            }
            &::after {
                bottom: 0;

                @media screen and #{$media_md} {
                    right: 0;
                }
            }
        }
    }

    // Style 2
    &-style-2 {
        .info-block__text {
            background-color: #f4f9ff;
            font-size: 1rem;
            color: $color_text_1;

            @media screen and #{$media_lg} {
                font-size: .9rem;
            }
            @media screen and #{$media_md} {
                font-size: 1rem;
            }
            @media screen and #{$media_sm} {
                max-height: 300px;
                font-size: .9rem;
            }

            p {
                color: inherit;
            }
        }
    }

    // Style 3
    &-style-3 {
        .info-block__text {
            background: linear-gradient(to bottom, #e0eeff 0%, #ffffff 100%);
            font-size: 1rem;
            color: $color_text_1;

            @media screen and #{$media_lg} {
                font-size: .9rem;
            }
            @media screen and #{$media_md} {
                font-size: 1rem;
            }
            @media screen and #{$media_sm} {
                max-height: 300px;
                font-size: .9rem;
            }

            &::before {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                height: 6px;
                background-color: $color_main_1;
            }

            p {
                color: inherit;
            }
        }
    }

    // Style 4
    &-style-4 {
        .info-block__img {
            order: 2;
        }
        .info-block__text {
            order: 1;
            background: linear-gradient(to bottom, #e0eeff 0%, #ffffff 100%);
            font-size: 1rem;
            color: $color_text_1;
            text-align: center;

            @media screen and #{$media_lg} {
                font-size: .9rem;
            }
            @media screen and #{$media_md} {
                font-size: 1rem;
            }
            @media screen and #{$media_sm} {
                max-height: 300px;
                font-size: .9rem;
            }

            &::before {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                height: 6px;
                background-color: $color_main_1;
            }

            p {
                color: inherit;
            }
        }
    }

    // Style 5
    &-style-5 {
        position: relative;
        padding-top: 28px;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 40px;
            width: 68px;
            height: 56px;
            background-image: url("../images/icon-quote.svg");
            background-repeat: no-repeat;
            z-index: 1;

            @media screen and #{$media_lg} {
                left: 30px;
            }
        }

        .info-block__img {
            order: 2;
        }
        .info-block__text {
            order: 1;
            padding: 45px 40px;
            background-color: #f4f9ff;
            font-size: 1rem;
            color: $color_text_1;

            @media screen and #{$media_lg} {
                padding: 45px 30px 35px;
                font-size: .9rem;
            }
            @media screen and #{$media_md} {
                font-size: 1rem;
            }
            @media screen and #{$media_sm} {
                max-height: 310px;
                font-size: .9rem;
            }

            p {
                color: inherit;
            }
        }
    }

    // Style 6
    &-style-6 {
        position: relative;
        padding-top: 21px;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 70px;
            width: 50px;
            height: 41px;
            background-image: url("../images/icon-quote.svg");
            background-repeat: no-repeat;
            background-size: cover;
            z-index: 1;
        }

        .info-block__img {
            order: 2;
        }
        .info-block__text {
            order: 1;
            padding: 45px 40px;
            background-color: #f4f9ff;
            font-size: 1rem;
            color: $color_text_1;
            border: 3px solid $color_main_1;
            border-right: 0;

            @media screen and #{$media_lg} {
                padding: 35px 30px 30px;
                font-size: .9rem;
            }
            @media screen and #{$media_md} {
                border: 3px solid $color_main_1;
                border-bottom: 0;
                font-size: 1rem;
            }
            @media screen and #{$media_sm} {
                max-height: 300px;
                padding: 35px 20px 20px;
                font-size: .9rem;
            }

            &::before {
                position: absolute;
                top: -3px;
                left: 40px;
                width: 110px;
                height: 3px;
                background-color: #f4f9ff;
            }

            p {
                color: inherit;
            }
        }
    }

    // Style 7
    &-style-7 {
        position: relative;
        padding-top: 44px;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 40px;
            width: 87px;
            height: 87px;
            border-radius: 100%;
            background-image: url("../images/icon-quote.svg");
            background-size: 38px;
            background-repeat: no-repeat;
            background-position: center;
            background-color: #fff;
            box-shadow: 0px 8px 20px rgba(36, 109, 198, .1);
            z-index: 1;
        }

        .info-block__img {
            order: 2;
        }
        .info-block__text {
            order: 1;
            padding: 45px 40px;
            background-color: #f4f9ff;
            font-size: 1rem;
            color: $color_text_1;

            @media screen and #{$media_lg} {
                padding: 45px 30px 30px;
                font-size: .9rem;
            }
            @media screen and #{$media_md} {
                font-size: 1rem;
            }
            @media screen and #{$media_sm} {
                max-height: 300px;
                font-size: .9rem;
            }

            &::before {
                position: absolute;
                top: -3px;
                left: 40px;
                width: 110px;
                height: 3px;
                background-color: #f4f9ff;
            }

            p {
                color: inherit;
            }
        }
    }

    // Style 8
    &-style-8 {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 248px;
            height: 227px;
            background-image: url("../images/icon-quote.svg");
            background-size: 248px;
            background-repeat: no-repeat;
            background-position: center;
            opacity: .07;
            z-index: 1;
        }

        .info-block__img {
            order: 2;
        }
        .info-block__text {
            order: 1;
            padding: 45px 40px;
            background-color: #fff;
            font-size: 1rem;
            color: $color_text_1;

            @media screen and #{$media_lg} {
                padding: 35px 30px;
                font-size: .9rem;
            }
            @media screen and #{$media_md} {
                font-size: 1rem;
            }
            @media screen and #{$media_sm} {
                max-height: 300px;
                font-size: .9rem;
            }

            p {
                color: inherit;
            }
        }
    }

    // Style 9
    &-style-9 {
        position: relative;

        .info-block__img {
            order: 2;
        }
        .info-block__text {
            order: 1;
            padding: 45px 40px;
            background-color: #fff;
            font-size: 1rem;
            color: $color_text_1;
            text-align: center;

            @media screen and #{$media_lg} {
                padding: 35px 30px 30px;
                font-size: .9rem;
            }
            @media screen and #{$media_md} {
                margin-bottom: 10px;
            }
            @media screen and #{$media_sm} {
                padding: 30px 20px;
                max-height: 300px;
            }

            &::before {
                position: absolute;
                top: 0;
                left: 50%;
                margin-left: -50px;
                width: 100px;
                height: 4px;
                background-color: $color_main_1;
            }
            &::after {
                position: absolute;
                bottom: 0;
                left: 50%;
                margin-left: -50px;
                width: 100px;
                height: 4px;
                background-color: $color_main_1;
            }

            p {
                color: inherit;
            }
        }
    }
}

// Info contact
.info-contact {
    display: flex;
    align-items: stretch;
    border-top: 3px solid $color_main_1;

    @media screen and #{$media_lg} {
        flex-wrap: wrap;
    }

    > .info-contact {
        flex: 0 0 50%;
        max-width: 50%;
        flex-direction: column;
        padding: 20px;
        border: 0;
        background-color: rgba($color_main_1, .05);

        @media screen and #{$media_lg} {
            flex: 0 0 100%;
            max-width: 100%;
        }

        .info-contact__img {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 120px;
            min-width: 120px;
            height: 120px;
            min-height: 120px;
            background-color: #fff;
            border-radius: 100%;
            overflow: hidden;

            > img {
                max-width: 100%;
            }
        }
        .info-contact__title {
            max-height: 53px;
            margin-bottom: 10px;
            overflow: hidden;

            > a {
                color: $color_main_3;

                &:hover,
                &:focus {
                    color: $color_main_1;
                    text-decoration: none;
                }
            }
        }
        .info-contact__subtitle {
            max-height: 78px;
            overflow: hidden;
            color: $color_grey_4;
        }
        .info-contact__adress,
        .info-contact__tel {
            max-height: 53px;
            overflow: hidden;
        }
        .info-contact__adress + .info-contact__tel {
            margin-top: 10px;
        }

        .icon {
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            top: 3px;
            width: 18px;
            height: 24px;
            margin-right: 10px;
            font-size: 1.3rem;
            color: $color_main_1;
        }
    }

    .info-contact-map {
        flex: 0 0 50%;
        max-width: 50%;

        @media screen and #{$media_lg} {
            flex: 0 0 100%;
            max-width: 100%;
        }

        .map {
            width: 100%;
            height: 100%;
            min-height: 290px;
        }
    }

    &-style-1 {
        margin-top: 60px;

        > .info-contact {
            padding: 40px;

            @media screen and #{$media_sm} {
                align-items: center;
                padding: 40px 20px;

                .info-contact__title,
                .info-contact__subtitle {
                    text-align: center;
                }
            }

            .info-contact__img {
                position: relative;
                top: -103px;
                margin-bottom: -63px;
                box-shadow: 0 10px 20px rgba($color_main_1, .08);
            }
            .info-contact__adress {
                margin-top: 40px;
            }
        }
    }
    &-style-2 {
        flex-direction: column;

        > .info-contact {
            flex: 0 0 100%;
            flex-direction: row;
            max-width: 100%;

            @media screen and #{$media_lg} {
                justify-content: center;
                text-align: center;
            }

            > div {
                width: 100%;
            }
            .info-contact__img {
                margin-right: 40px;
                box-shadow: 0 0 20px rgba($color_main_1, .08);

                @media screen and #{$media_lg} {
                    margin-right: 0;
                }
            }
            .info-contact__title {
                margin-top: 20px;
            }
            .info-contact__adress {
                margin-top: 20px;
            }
        }
        .info-contact-map {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

}

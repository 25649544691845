html {
    font-size: 16px;

    @media screen and #{$media_lg} {
        font-size: 14px;
    }
    @media screen and #{$media_xxs} {
        font-size: 12px;
    }
}

body {
    font-family: $font_body;
    color: $color_body;
    letter-spacing: .01em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

b, strong {
    font-weight: 500;
}

label {
    font-weight: bold;
    color: $color_main_1;
}

ul {
    padding-left: 20px;
}

a {
    text-decoration: none;
    transition: color .2s ease;

    &:hover {
        text-decoration: underline;
        color: $color_main_3;
    }
    &:focus {
        text-decoration: none;
        outline: none;
        color: $color_main_3;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 20px;
    font-family: $font_headings;
    font-weight: 300;
    color: $color_grey_2;
}
h1,
.h1 {
    margin-bottom: 54px;
    font-size: 3.4rem;

    @media screen and #{$media_lg} {
        font-size: 3rem;
    }
    @media screen and #{$media_md} {
        font-size: 2.8rem;
    }
    @media screen and #{$media_sm} {
        font-size: 2.6rem;
    }
}

.title {
    position: relative;
    margin-top: -6px;
    margin-bottom: 20px;
    color: #000;
    font-weight: 500;

    &_main {
        margin-bottom: 30px;
        text-transform: uppercase;
    }
    &_partners {
        margin-bottom: 20px;
    }
    &_articles {
        margin-bottom: 34px;
    }
}

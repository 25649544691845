@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");
html {
  font-size: 16px;
}

@media screen and (max-width: 991px) {
  html {
    font-size: 14px;
  }
}

@media screen and (max-width: 380px) {
  html {
    font-size: 12px;
  }
}

body {
  font-family: "Roboto", sans-serif;
  color: #7a7a7a;
  letter-spacing: .01em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

b, strong {
  font-weight: 500;
}

label {
  font-weight: bold;
  color: #18afd3;
}

ul {
  padding-left: 20px;
}

a {
  text-decoration: none;
  transition: color .2s ease;
}

a:hover {
  text-decoration: underline;
  color: #4c6575;
}

a:focus {
  text-decoration: none;
  outline: none;
  color: #4c6575;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #5a5a5a;
}

h1,
.h1 {
  margin-bottom: 54px;
  font-size: 3.4rem;
}

@media screen and (max-width: 991px) {
  h1,
  .h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) {
  h1,
  .h1 {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 575px) {
  h1,
  .h1 {
    font-size: 2.6rem;
  }
}

.title {
  position: relative;
  margin-top: -6px;
  margin-bottom: 20px;
  color: #000;
  font-weight: 500;
}

.title_main {
  margin-bottom: 30px;
  text-transform: uppercase;
}

.title_partners {
  margin-bottom: 20px;
}

.title_articles {
  margin-bottom: 34px;
}

html {
  height: 100%;
}

body {
  position: relative;
  height: 100%;
  padding: 0;
  margin: 0;
  background: #fff;
}

body::-webkit-scrollbar {
  width: 10px;
  background-color: #fafafa;
}

body::-webkit-scrollbar-thumb {
  background-color: #18afd3;
}

body.fancybox-active {
  margin-right: 10px !important;
}

.img {
  max-width: 100%;
}

.icon {
  display: inline-block;
  text-decoration: none;
  outline: none;
  background-size: cover;
  background-repeat: no-repeat;
}

.icon.icon-keypad {
  width: 32px;
  height: 32px;
  background-image: url("../images/icon-keypad.svg");
}

.icon.icon-time {
  width: 32px;
  height: 32px;
  background-image: url("../images/icon-time.svg");
}

.icon.icon-feature-one {
  width: 117px;
  height: 100px;
  background-image: url("../images/icon-feature-one.svg");
}

.icon.icon-feature-two {
  width: 87px;
  height: 106px;
  background-image: url("../images/icon-feature-two.svg");
}

.icon.icon-feature-three {
  width: 102px;
  height: 102px;
  background-image: url("../images/icon-feature-three.svg");
}

.icon.icon-feature-1 {
  width: 65px;
  height: 76px;
  background-image: url("../images/icon-feature-1.svg");
}

.icon.icon-feature-2 {
  width: 69px;
  height: 68px;
  background-image: url("../images/icon-feature-2.svg");
}

.icon.icon-feature-3 {
  width: 55px;
  height: 64px;
  background-image: url("../images/icon-feature-3.svg");
}

.icon.icon-feature-4 {
  width: 74px;
  height: 63px;
  background-image: url("../images/icon-feature-4.svg");
}

.icon.icon-feature-5 {
  width: 67px;
  height: 67px;
  background-image: url("../images/icon-feature-5.svg");
}

.icon.icon-feature-6 {
  width: 70px;
  height: 59px;
  background-image: url("../images/icon-feature-6.svg");
}

.icon.icon-feature-7 {
  width: 55px;
  height: 67px;
  background-image: url("../images/icon-feature-7.svg");
}

.icon.icon-feature-8 {
  width: 46px;
  height: 78px;
  background-image: url("../images/icon-feature-8.svg");
}

.icon.icon-feature-9 {
  width: 73px;
  height: 64px;
  background-image: url("../images/icon-feature-9.svg");
}

.icon.icon-feature-10 {
  width: 69px;
  height: 69px;
  background-image: url("../images/icon-feature-10.svg");
}

.icon.icon-feature-11 {
  width: 62px;
  height: 73px;
  background-image: url("../images/icon-feature-11.svg");
}

.icon.icon-feature-12 {
  width: 69px;
  height: 54px;
  background-image: url("../images/icon-feature-12.svg");
}

.main {
  position: relative;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
}

.main__content {
  position: relative;
  z-index: 1;
}

.bg-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: 50% 50%;
  background-size: cover;
  z-index: -1;
}

.bg-image > * {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: 50% 50%;
  background-size: cover;
}

.bg-image.bg-pattern {
  background-size: auto;
}

.bg-image.bg-pattern > * {
  background-size: auto;
}

.bg-image-parallax img,
.bg-image img {
  width: 100%;
  height: 100%;
  font-family: "object-fit: cover";
  object-fit: cover;
}

.breadcrumbs + .header {
  margin-top: 40px;
}

.header {
  position: relative;
  display: block;
  z-index: 1;
}

.header-image {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  min-height: 420px;
  padding: 40px;
}

@media screen and (max-width: 767px) {
  .header-image {
    min-height: 320px;
  }
}

@media screen and (max-width: 767px) {
  .header-image {
    min-height: 300px;
    padding: 20px;
  }
}

.header-image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 10%, rgba(0, 0, 0, 0) 40%);
  z-index: 0;
}

.header-image img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.header-image__title, .header-image__views, .header-image__tags {
  position: relative;
  z-index: 1;
}

.header-image__title {
  display: block;
  margin-bottom: 20px;
  color: #fff;
  font-weight: 700;
}

@media screen and (max-width: 767px) {
  .header-image__title {
    font-size: 2.3rem;
  }
}

@media screen and (max-width: 575px) {
  .header-image__title {
    font-size: 2.2rem;
  }
}

.header-image__views {
  display: flex;
  color: #fff;
}

.header-image__views .icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0px;
  height: 24px;
  margin-right: 8px;
  font-size: 1.5rem;
}

.header-image__tags {
  display: flex;
  align-items: flex-start;
  color: #fff;
}

.header-image__tags .icon {
  display: inline-block;
  margin-right: 5px;
  margin-left: 10px;
}

@media screen and (max-width: 575px) {
  .header-image__tags .icon {
    margin-left: 0;
  }
}

.header-image__tags-list {
  display: inline-block;
  margin: 0;
  padding: 0;
}

.header-image__tags-list li {
  display: inline-block;
  margin: 0 4px 0 0;
  padding: 0;
  list-style: none;
}

.header-image__tags-list li::after {
  content: "/";
  display: inline-block;
  margin-left: 3px;
  color: #e5e5e5;
}

.header-image__tags-list li:last-child {
  margin-right: 0;
}

.header-image__tags-list li:last-child::after {
  content: "";
}

.header-image__tags-list li a {
  position: relative;
  display: inline-block;
  top: -1px;
  color: #fff;
}

.header-image__tags-list li a:hover, .header-image__tags-list li a:focus {
  color: #fff;
  text-decoration: underline;
}

.footer {
  display: block;
  background-color: #fff;
}

.footer .map {
  min-height: 440px;
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.vertical-gap {
  margin-top: -30px;
}

.vertical-gap > [class*="col"] {
  padding-top: 30px;
}

.row.gap-20 {
  margin-right: -10px;
  margin-left: -10px;
}

.row.gap-20 > [class*="col"] {
  padding-right: 10px;
  padding-left: 10px;
}

.row.gap-20.vertical-gap {
  margin-top: -20px;
}

.row.gap-20.vertical-gap > [class*="col"] {
  padding-top: 20px;
}

.row.gap-10 {
  margin-right: -5px;
  margin-left: -5px;
}

.row.gap-10 > [class*="col"] {
  padding-right: 5px;
  padding-left: 5px;
}

.row.gap-10.vertical-gap {
  margin-top: -10px;
}

.row.gap-10.vertical-gap > [class*="col"] {
  padding-top: 10px;
}

/*
 * Text Color Helper
 */
.text-white {
  color: #fff !important;
}

.text-black {
  color: #000 !important;
}

.text-main-1 {
  color: #18afd3 !important;
}

.text-main-2 {
  color: #f57e57 !important;
}

.text-main-3 {
  color: #4c6575 !important;
}

.text-grey-1 {
  color: #7a7a7a !important;
}

.text-grey-2 {
  color: #5a5a5a !important;
}

.text-grey-3 {
  color: #e5e5e5 !important;
}

/*
 * Margin Helper
 */
.m-0 {
  margin: 0px !important;
}

.m-1 {
  margin: 1px !important;
}

.m-2 {
  margin: 2px !important;
}

.m-3 {
  margin: 3px !important;
}

.m-4 {
  margin: 4px !important;
}

.m-5 {
  margin: 5px !important;
}

.m-6 {
  margin: 6px !important;
}

.m-7 {
  margin: 7px !important;
}

.m-8 {
  margin: 8px !important;
}

.m-9 {
  margin: 9px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-30 {
  margin: 30px !important;
}

.m-40 {
  margin: 40px !important;
}

.m-50 {
  margin: 50px !important;
}

.m-60 {
  margin: 60px !important;
}

.m-70 {
  margin: 70px !important;
}

.m-80 {
  margin: 80px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-1 {
  margin-top: 1px !important;
}

.mt-2 {
  margin-top: 2px !important;
}

.mt-3 {
  margin-top: 3px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-6 {
  margin-top: 6px !important;
}

.mt-7 {
  margin-top: 7px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-9 {
  margin-top: 9px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-1 {
  margin-left: 1px !important;
}

.ml-2 {
  margin-left: 2px !important;
}

.ml-3 {
  margin-left: 3px !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-6 {
  margin-left: 6px !important;
}

.ml-7 {
  margin-left: 7px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.ml-9 {
  margin-left: 9px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.ml-70 {
  margin-left: 70px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-1 {
  margin-right: 1px !important;
}

.mr-2 {
  margin-right: 2px !important;
}

.mr-3 {
  margin-right: 3px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-6 {
  margin-right: 6px !important;
}

.mr-7 {
  margin-right: 7px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mr-9 {
  margin-right: 9px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-1 {
  margin-bottom: 1px !important;
}

.mb-2 {
  margin-bottom: 2px !important;
}

.mb-3 {
  margin-bottom: 3px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-6 {
  margin-bottom: 6px !important;
}

.mb-7 {
  margin-bottom: 7px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-9 {
  margin-bottom: 9px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.navbar {
  position: relative;
  display: flex;
  align-items: center;
  padding: 30px 0;
  font-family: "Roboto", sans-serif;
  background-color: #fff;
  z-index: 10;
}

.navbar .navbar-close {
  display: none;
}

@media screen and (max-width: 991px) {
  .navbar {
    padding: 20px 0;
  }
  .navbar .navbar-close {
    position: absolute;
    display: block;
    top: 109px;
    left: 0;
    width: 100vw;
    height: 100vh;
    cursor: pointer;
    background-color: rgba(76, 101, 117, 0.8);
    opacity: 0;
    visibility: hidden;
    transition: visibility .2s ease, opacity .2s ease;
    z-index: 9;
  }
  .navbar .navbar-close.active {
    opacity: 1;
    visibility: visible;
  }
}

.navbar.is_stuck {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.04);
}

.navbar > [class*="container"] {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  z-index: 10;
}

.navbar .navbar-burger {
  position: absolute;
  display: none;
  top: 50%;
  right: 40px;
  width: 24px;
  height: 16px;
  margin-top: -8px;
  cursor: pointer;
}

.navbar .navbar-burger > span {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #4c6575;
}

@media screen and (max-width: 991px) {
  .navbar .navbar-burger {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}

.navbar .navbar-logo {
  width: 200px;
  min-width: 200px;
}

.navbar .navbar-logo a {
  display: block;
}

.navbar .navbar-logo img {
  max-width: 100%;
}

.navbar .navbar-content {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 991px) {
  .navbar .navbar-content {
    position: absolute;
    top: 0;
    right: 0;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    padding: 40px;
    background-color: #fff;
    box-shadow: -1px 0 0 0 rgba(0, 0, 0, 0.04);
    transition: transform .4s ease;
    transform: translateX(100%);
    z-index: 11;
  }
  .navbar .navbar-content.active {
    transform: translateX(0);
  }
}

.navbar .navbar-content > li {
  display: inline-block;
  padding: 0;
  margin: 0;
  font-size: .74rem;
  list-style: none;
}

.navbar .navbar-content > li a {
  display: block;
  padding: 12px 10px;
  font-weight: 500;
  color: #5a5a5a;
  text-transform: uppercase;
  transition: color .2s ease;
}

@media screen and (max-width: 1199px) {
  .navbar .navbar-content > li a {
    padding: 12px 6px;
  }
}

@media screen and (max-width: 991px) {
  .navbar .navbar-content > li a {
    padding: 10px 6px;
    font-size: 1rem;
  }
}

.navbar .navbar-content > li a:hover, .navbar .navbar-content > li a:focus {
  color: #18afd3;
  text-decoration: none;
}

.social-icons {
  padding: 0;
  margin: 0;
  font-size: 0;
  list-style: none;
}

.social-icons > li {
  display: inline-block;
}

.social-icons > li + li {
  margin-left: 10px;
}

.social-icons > li > * {
  cursor: pointer;
  display: block;
  width: 28px;
  height: 28px;
  font-size: .9rem;
  line-height: 2em;
  color: #fff;
  text-decoration: none;
  text-align: center;
  transition: background-color .2s ease;
}

.social-icons > li > *:hover, .social-icons > li > *:focus {
  text-decoration: none;
}

.social-icons_lg > li > * {
  width: 42px;
  height: 42px;
  font-size: 1.3rem;
}

.social-icons_round > li > * {
  border-radius: 100%;
}

.social-icons > li > .social-icons__facebook {
  background-color: #475995;
}

.social-icons > li > .social-icons__facebook:hover {
  background-color: rgba(71, 89, 149, 0.8);
}

.social-icons > li > .social-icons__googleplus {
  background-color: #c14d3d;
}

.social-icons > li > .social-icons__googleplus:hover {
  background-color: rgba(193, 77, 61, 0.8);
}

.social-icons > li > .social-icons__instagram {
  background-color: #d12054;
}

.social-icons > li > .social-icons__instagram:hover {
  background-color: rgba(209, 32, 84, 0.8);
}

.social-icons > li > .social-icons__odnoklassniki {
  background-color: #ee8208;
}

.social-icons > li > .social-icons__odnoklassniki:hover {
  background-color: rgba(238, 130, 8, 0.8);
}

.social-icons > li > .social-icons__paypal {
  background-color: #1d3283;
}

.social-icons > li > .social-icons__paypal:hover {
  background-color: rgba(29, 50, 131, 0.8);
}

.social-icons > li > .social-icons__skype {
  background-color: #62aded;
}

.social-icons > li > .social-icons__skype:hover {
  background-color: rgba(98, 173, 237, 0.8);
}

.social-icons > li > .social-icons__tumblr {
  background-color: #384961;
}

.social-icons > li > .social-icons__tumblr:hover {
  background-color: rgba(56, 73, 97, 0.8);
}

.social-icons > li > .social-icons__twitter {
  background-color: #6da9de;
}

.social-icons > li > .social-icons__twitter:hover {
  background-color: rgba(109, 169, 222, 0.8);
}

.social-icons > li > .social-icons__vimeo {
  background-color: #69b5e7;
}

.social-icons > li > .social-icons__vimeo:hover {
  background-color: rgba(105, 181, 231, 0.8);
}

.social-icons > li > .social-icons__vk {
  background-color: #657da0;
}

.social-icons > li > .social-icons__vk:hover {
  background-color: rgba(101, 125, 160, 0.8);
}

.social-icons > li > .social-icons__youtube {
  background-color: #c6271e;
}

.social-icons > li > .social-icons__youtube:hover {
  background-color: rgba(198, 39, 30, 0.8);
}

.info {
  padding: 30px 0 30px;
  background-color: #f5f5f5;
}

.info-item {
  display: inline-flex;
  align-items: center;
}

.info-item a:not(.btn) {
  color: inherit;
  text-decoration: none;
}

.info-item a:not(.btn):hover, .info-item a:not(.btn):focus {
  color: inherit;
  text-decoration: underline;
}

.info-item:last-of-type {
  margin-right: 0;
}

.info-item__icon {
  margin-right: 15px;
  font-size: 0;
  color: #18afd3;
}

.info-item__icon > span {
  font-size: 1.7rem;
}

.info-item__content {
  display: block;
}

.info-item__title {
  margin-top: -2px;
  font-weight: 500;
  color: #5a5a5a;
  line-height: normal;
}

.info-item__subtitle {
  color: #7a7a7a;
  line-height: normal;
}

.specialist-item {
  display: block;
  color: #fff;
}

.specialist-item__title, .specialist-item__text {
  display: block;
  margin-top: 10px;
}

@media screen and (max-width: 575px) {
  .specialist-item__title, .specialist-item__text {
    text-align: center;
  }
}

.specialist-item__title {
  font-size: 1.3rem;
  line-height: normal;
}

.specialist-item__text {
  font-weight: 300;
  color: rgba(255, 255, 255, 0.8);
}

.specialist-item:hover, .specialist-item:focus {
  color: #fff;
  text-decoration: none;
}

.specialist-item__overlay {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: 3.2rem;
  color: #fff;
  z-index: 1;
}

.specialist-item__overlay > span {
  opacity: 0;
  transition: transform .2s ease, opacity .3s ease;
  transform: scale(1.4);
}

.specialist-item__overlay::before, .specialist-item__overlay::after {
  content: "";
  position: absolute;
  border-radius: 100%;
}

.specialist-item__overlay::before {
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  background-color: #4c6575;
  opacity: 0;
  transition: opacity .3s ease;
}

.specialist-item__overlay::after {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: inset 0 0 0 1px rgba(76, 101, 117, 0), inset 0 0 0 1px rgba(255, 255, 255, 0);
  transition: box-shadow .2s ease;
  transition-delay: .15s;
  z-index: 1;
}

.specialist-item:hover .specialist-item__overlay > span {
  opacity: 1;
  transform: scale(1);
}

.specialist-item:hover .specialist-item__overlay::before {
  opacity: .95;
}

.specialist-item:hover .specialist-item__overlay::after {
  box-shadow: inset 0 0 0 8px #4c6575, inset 0 0 0 10px #fff;
}

.specialist-item__img {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 232px;
  max-width: 100%;
  border-radius: 100%;
  background-color: #fff;
  overflow: hidden;
}

.specialist-item__img::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.specialist-item__img img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  font-family: "object-fit: cover";
  object-fit: cover;
}

.specialist-item-style-2 {
  display: flex;
  align-items: center;
  padding: 30px 60px;
  margin-left: -20px;
  margin-right: -20px;
  background-color: #4c6575;
}

@media screen and (max-width: 575px) {
  .specialist-item-style-2 {
    flex-direction: column;
    align-items: center;
  }
}

.specialist-item-style-2 .specialist-item__img {
  min-width: 200px;
  margin-left: 0;
  margin-right: 30px;
}

@media screen and (max-width: 575px) {
  .specialist-item-style-2 .specialist-item__img {
    margin-left: auto;
    margin-right: auto;
  }
}

.btn {
  border: 0;
  border-radius: 0;
  color: #4c6575;
  text-decoration: none;
}

.btn:hover, .btn:focus {
  color: #4c6575;
  text-decoration: none;
  box-shadow: none;
}

.btn-round {
  border-radius: 40px;
}

.btn-main {
  display: inline-block;
  padding: 12px 50px;
  background-color: #18afd3;
  box-shadow: inset 0 0 0 1px rgba(24, 175, 211, 0), inset 0 0 0 1px rgba(255, 255, 255, 0);
  font-weight: 300;
  color: #fff;
  transition: box-shadow .2s ease;
}

.btn-main:hover {
  color: #fff;
  box-shadow: inset 0 0 0 5px #18afd3, inset 0 0 0 6px #fff;
}

.btn-main:focus {
  color: #fff;
}

.btn-main-2 {
  background-color: #f57e57;
  box-shadow: inset 0 0 0 1px rgba(245, 126, 87, 0), inset 0 0 0 1px rgba(255, 255, 255, 0);
}

.btn-main-2:hover {
  color: #fff;
  box-shadow: inset 0 0 0 5px #f57e57, inset 0 0 0 6px #fff;
}

button {
  cursor: pointer;
}

/* Placeholders */
::placeholder,
.form-control::placeholder {
  color: inherit;
}

/* Remove outline from focused buttons */
button:focus {
  outline: 0;
}

/* From */
.form {
  /* fix for select on iOs devices */
}

.form .form-group {
  position: relative;
  margin-bottom: 0;
  overflow: hidden;
}

.form .form-group:not(.field)::before, .form .form-group:not(.field)::after {
  display: none;
}

.form .form-group::before, .form .form-group::after {
  content: "";
  position: absolute;
  left: 15px;
}

.form .form-group::before {
  top: 39px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 23px;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 3;
}

.form .form-group::after {
  right: 15px;
  bottom: 0;
  height: 1px;
  border-bottom: 1px solid #4c6575;
  opacity: 0;
  transition: transform .4s ease, opacity .2s ease;
  transform: scaleX(0);
  z-index: 1;
}

.form .form-group:hover::after {
  opacity: 1;
  transform: scaleX(1);
}

.form .form-group.field-vase-ima::before {
  background-image: url("../images/icon-person.svg");
}

.form .form-group.field-nomer-telefona::before {
  background-image: url("../images/icon-keypad.svg");
  background-size: cover;
}

.form .form-group.field-e-mail::before {
  background-image: url("../images/icon-mail.svg");
}

.form .form-group.field-data::before {
  background-image: url("../images/icon-date.svg");
}

.form .form-group.field-vrema::before {
  background-image: url("../images/icon-time.svg");
  background-size: cover;
}

.form .form-group.field-vase-soobsenie::before {
  background-image: url("../images/icon-chat.svg");
}

.form__control {
  position: relative;
  height: 45px;
  padding: 10px 0 10px 40px;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #e5e5e5;
  box-shadow: none;
  color: rgba(90, 90, 90, 0.6);
  transition: border-color .3s ease, color .2s ease;
  transition-delay: .1s;
}

.form__control::placeholder {
  color: rgba(90, 90, 90, 0.6);
  opacity: 1;
  transition: color .2s ease;
}

.form__control:hover, .form__control:focus {
  color: #5a5a5a;
  box-shadow: none;
  border-color: #4c6575;
}

.form__control:hover::placeholder, .form__control:focus::placeholder {
  color: #5a5a5a;
}

.form__control:focus {
  border-color: #18afd3;
  z-index: 2;
}

.form textarea,
.form textarea.form-control,
.form textarea.form-control.form__control {
  min-height: 45px;
  height: 45px;
  max-height: 160px;
}

.form textarea.form__control_2,
.form textarea.form-control.form__control_2,
.form textarea.form-control.form__control.form__control_2 {
  min-height: 48px;
  height: 164px;
  max-height: 190px;
}

.form label {
  font-family: "Roboto", sans-serif;
  font-size: .9rem;
}

.form select.form-control:not([size]):not([multiple]) {
  height: 34px;
}

.form select.form-control {
  color: #7a7a7a;
  cursor: pointer;
}

.form select.form-control:focus {
  cursor: pointer;
}

.form select.form-control option {
  color: #7a7a7a;
}

@-moz-document url-prefix() {
  .form select.form-control option {
    color: inherit;
  }
}

.form select.form-control[multiple] option {
  color: inherit;
}

.form select.form-control {
  background-color: #fff;
}

.form-control-descrtiption {
  font-size: 0.8rem;
  margin-top: 7px;
  color: #9a9a9a;
}

.form-control-descrtiption a {
  text-decoration: none;
}

.form-check {
  display: block;
  margin: 6px 0;
  padding-left: 0;
}

.form__check {
  cursor: pointer;
  position: relative;
  display: block;
  margin-bottom: 0;
  padding: 3px 0 4px 40px;
  color: #4c6575;
  font-size: .6rem;
  font-weight: 400;
  letter-spacing: 0;
  vertical-align: middle;
  user-select: none;
}

.form__check-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.form__check-input ~ .form__check-checkmark {
  background-color: #fff;
  border: 1px solid #5a5a5a;
  transition: background-color .15s ease, border-color .15s ease;
}

.form__check-input:checked ~ .form__check-checkmark {
  background-color: #fff;
  border-color: #5a5a5a;
}

.form__check-input:checked ~ .form__check-checkmark::after, .form__check-input:checked ~ .form__check-checkmark::before {
  display: block;
}

.form__check-checkmark {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 28px;
  width: 28px;
  border-radius: 0;
  background-color: #fff;
}

.form__check-checkmark::after, .form__check-checkmark::before {
  content: '';
  position: absolute;
  display: none;
  transform: rotate(-45deg);
  transform-origin: 0% 100%;
}

.form__check-checkmark::after {
  top: 10px;
  left: 11px;
  width: 3px;
  height: 7px;
  border-top: solid #4c6575;
  border-width: 7px;
  animation: check-one 0.6s linear;
}

@keyframes check-one {
  0% {
    border-width: 0px;
  }
  20% {
    border-width: 7px;
  }
  100% {
    border-width: 7px;
  }
}

.form__check-checkmark::before {
  top: 15px;
  left: 12px;
  width: 13px;
  height: 3px;
  border-left: solid #4c6575;
  border-width: 13px;
  animation: check-two 0.6s linear;
}

@keyframes check-two {
  0% {
    border-width: 0px;
  }
  20% {
    border-width: 0px;
  }
  40% {
    border-width: 13px;
  }
  100% {
    border-width: 13px;
  }
}

.form .form__file {
  display: inline-block;
  position: relative;
  width: 84px;
  height: 72px;
  margin-right: 5px;
  margin-bottom: 10px;
}

.form .form__file:last-of-type {
  margin-right: 0;
}

.form .form__file--input {
  visibility: hidden;
  width: 0;
  height: 0;
}

.form .form__file--label {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  background-image: url(../../assets/images/add-img-default.png);
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  transition: box-shadow .2s ease;
}

.form .form__file--label:hover, .form .form__file--label:focus {
  box-shadow: inset 0 0 0 1px #18afd3;
}

.form .form__file--label::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.form .form__file--img {
  width: 100%;
  height: 100%;
}

.form .form__file--remove {
  position: absolute;
  z-index: 1;
  width: 14px;
  height: 14px;
  top: 5px;
  right: 5px;
  border-radius: 7px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
  transition: background-color .2s ease, color .2s ease;
}

.form .form__file--remove > .icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 1px;
  top: 1px;
  width: calc(100% - 1px);
  height: calc(100% - 1px);
  font-size: 1rem;
}

.form .form__file--remove:hover, .form .form__file--remove:focus {
  background-color: #f57e57;
  color: #fff;
  text-decoration: none;
}

/* Form Style Feedback */
.form__feedback .form__control {
  border: 1px solid #5a5a5a;
  border-radius: 2px;
  font-size: 0.75rem;
  transition: border-color .2s ease, box-shadow .2s ease;
}

.form__feedback .form__control:hover, .form__feedback .form__control:focus {
  border-color: #18afd3;
}

.form__feedback .form__control:focus {
  box-shadow: 0 0 1px 1px rgba(24, 175, 211, 0.2);
}

.form__feedback .form__control.active {
  border-color: #f57e57;
}

.form__feedback .form__control.active:focus {
  box-shadow: 0 0 1px 1px rgba(245, 126, 87, 0.2);
}

.form__signin .form__control {
  height: 40px;
  padding: 10px 14px;
  border: 1px solid #5a5a5a;
  border-radius: 2px;
  font-size: 0.8rem;
  transition: border-color .2s ease, box-shadow .2s ease;
}

.form__signin .form__control:hover, .form__signin .form__control:focus {
  border-color: #18afd3;
}

.form__signin .form__control:focus {
  box-shadow: 0 0 1px 1px rgba(24, 175, 211, 0.2);
}

.form__signin .form__control.active {
  border-color: #f57e57;
}

.form__signin .form__control.active:focus {
  box-shadow: 0 0 1px 1px rgba(245, 126, 87, 0.2);
}

.form__signin--btn {
  width: 280px;
  padding: 6px 20px;
  font-size: 1.2rem;
}

.form__signin--link {
  text-decoration: none;
  font-size: 0.9rem;
}

.form__signin--link:hover {
  text-decoration: underline;
}

.form__signin .form-check .form__check {
  padding-left: 24px;
  font-size: 0.73rem;
  font-weight: 400;
  color: #000;
}

.form__signin .form-check .form__check-checkmark {
  top: 2px;
}

.form__ad .form__control {
  height: 36px;
  padding: 8px 14px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  font-size: 0.8rem;
  transition: border-color .2s ease, box-shadow .2s ease;
}

.form__ad .form__control:hover, .form__ad .form__control:focus {
  border-color: #18afd3;
}

.form__ad .form__control:focus {
  box-shadow: 0 0 1px 1px rgba(24, 175, 211, 0.2);
}

.form__ad .form__control.active {
  border-color: #f57e57;
}

.form__ad .form__control.active:focus {
  box-shadow: 0 0 1px 1px rgba(245, 126, 87, 0.2);
}

.form__ad--btn {
  width: 280px;
  padding: 6px 20px;
  font-size: 1.2rem;
}

.form__ad--link {
  text-decoration: none;
  font-size: 0.9rem;
}

.form__ad--link:hover {
  text-decoration: underline;
}

.form__ad .form-check .form__check {
  padding-left: 24px;
  font-size: 0.73rem;
  font-weight: 400;
  color: #000;
}

.form__ad .form-check .form__check-checkmark {
  top: 2px;
}

.mfp-wrap::-webkit-scrollbar {
  width: 10px;
  background-color: #fafafa;
}

.mfp-wrap::-webkit-scrollbar-thumb {
  background-color: #18afd3;
}

.modal {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.modal .container {
  position: relative;
  z-index: 1;
}

.modal .mfp-close {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 40px;
  padding-right: 40px;
  padding-bottom: 300%;
  color: #fff;
  z-index: 0;
}

@media screen and (max-width: 575px) {
  .modal .mfp-close {
    align-items: center;
    justify-content: center;
    left: auto;
    bottom: auto;
    width: 50px;
    height: 50px;
    z-index: 1;
  }
}

.modal .modal-content {
  padding: 0 20px;
  border-radius: 0;
}

.feature {
  display: inline-flex;
}

.feature-grid {
  position: relative;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  z-index: 1;
}

@media screen and (max-width: 575px) {
  .feature-grid {
    flex-direction: column;
  }
}

.feature-1 {
  position: relative;
  flex: 0 0 33.3333333333%;
  justify-content: space-between;
  max-width: 33.3333333333%;
  padding: 50px 30px;
  overflow: hidden;
  color: #fff;
  transition: box-shadow .2s ease;
}

@media screen and (max-width: 991px) {
  .feature-1 {
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .feature-1 {
    padding: 20px;
  }
}

@media screen and (max-width: 575px) {
  .feature-1 {
    flex: 0 0 100%;
    flex-direction: row;
    align-items: flex-start;
    max-width: 100%;
    padding: 20px 20px;
    text-align: left;
  }
}

.feature-1:hover, .feature-1:focus {
  color: #fff;
  text-decoration: none;
}

.feature-1:nth-child(4n+1) {
  background-color: #18afd3;
  box-shadow: inset 0 0 0 1px rgba(24, 175, 211, 0), inset 0 0 0 1px rgba(255, 255, 255, 0);
}

.feature-1:nth-child(4n+1):hover {
  box-shadow: inset 0 0 0 8px #18afd3, inset 0 0 0 10px #fff;
}

.feature-1:nth-child(4n+2) {
  background-color: #f57e57;
  box-shadow: inset 0 0 0 1px rgba(245, 126, 87, 0), inset 0 0 0 1px rgba(255, 255, 255, 0);
}

.feature-1:nth-child(4n+2):hover {
  box-shadow: inset 0 0 0 8px #f57e57, inset 0 0 0 10px #fff;
}

.feature-1:nth-child(4n+3) {
  background-color: #4c6575;
  box-shadow: inset 0 0 0 1px rgba(76, 101, 117, 0), inset 0 0 0 1px rgba(255, 255, 255, 0);
}

.feature-1:nth-child(4n+3):hover {
  box-shadow: inset 0 0 0 8px #4c6575, inset 0 0 0 10px #fff;
}

.feature-1 .feature-title {
  display: block;
  margin-bottom: 10px;
  font-size: 1.4rem;
  font-weight: 500;
}

.feature-1 .feature-text {
  display: block;
  line-height: normal;
}

@media screen and (max-width: 991px) {
  .feature-1 .feature-text {
    margin-bottom: 15px;
  }
}

.feature-2 {
  flex: 0 0 100%;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px 0 0;
  text-align: center;
}

.feature-2 .feature-icon {
  display: inline-flex;
  align-items: center;
  height: 100px;
}

.feature-2 .feature-title {
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
}

.numbers-title {
  margin-bottom: 0;
  color: #fff;
}

.numbers-text {
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.8);
}

.info-block {
  display: flex;
  align-items: stretch;
}

@media screen and (max-width: 767px) {
  .info-block {
    flex-wrap: wrap;
  }
}

.info-block > div {
  flex: 0 0 50%;
  max-width: 50%;
}

@media screen and (max-width: 767px) {
  .info-block > div {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.info-block__img {
  height: 100%;
  max-height: 320px;
  overflow: hidden;
}

.info-block__img img {
  width: 100%;
  height: 320px;
  font-family: "object-fit: cover";
  object-fit: cover;
}

@media screen and (max-width: 991px) {
  .info-block__img img {
    height: 280px;
  }
}

@media screen and (max-width: 767px) {
  .info-block__img img {
    height: 220px;
  }
}

@media screen and (max-width: 767px) {
  .info-block__img img {
    height: 200px;
  }
}

.info-block__text {
  position: relative;
  display: flex;
  align-items: center;
  max-height: 320px;
  padding: 25px 40px;
  font-size: 1.2rem;
  font-weight: 300;
}

@media screen and (max-width: 991px) {
  .info-block__text {
    max-height: 280px;
    padding: 25px 30px;
    font-size: .9rem;
  }
}

.info-block__text::after, .info-block__text::before {
  content: "";
}

.info-block__text > div {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
}

.info-block__text p:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .info-block-style-1 .info-block__text {
    font-size: 1rem;
  }
}

@media screen and (max-width: 575px) {
  .info-block-style-1 .info-block__text {
    max-height: 300px;
    font-size: .9rem;
  }
}

.info-block-style-1 .info-block__text::before, .info-block-style-1 .info-block__text::after {
  position: absolute;
  right: 100px;
  left: 40px;
  height: 6px;
  background-color: #18afd3;
}

@media screen and (max-width: 767px) {
  .info-block-style-1 .info-block__text::before, .info-block-style-1 .info-block__text::after {
    top: 0;
    right: auto;
    bottom: 0;
    left: auto;
    width: 4px;
    height: auto;
  }
}

.info-block-style-1 .info-block__text::before {
  top: 0;
}

@media screen and (max-width: 767px) {
  .info-block-style-1 .info-block__text::before {
    left: 0;
  }
}

.info-block-style-1 .info-block__text::after {
  bottom: 0;
}

@media screen and (max-width: 767px) {
  .info-block-style-1 .info-block__text::after {
    right: 0;
  }
}

.info-block-style-2 .info-block__text {
  background-color: #f4f9ff;
  font-size: 1rem;
  color: #2c527f;
}

@media screen and (max-width: 991px) {
  .info-block-style-2 .info-block__text {
    font-size: .9rem;
  }
}

@media screen and (max-width: 767px) {
  .info-block-style-2 .info-block__text {
    font-size: 1rem;
  }
}

@media screen and (max-width: 575px) {
  .info-block-style-2 .info-block__text {
    max-height: 300px;
    font-size: .9rem;
  }
}

.info-block-style-2 .info-block__text p {
  color: inherit;
}

.info-block-style-3 .info-block__text {
  background: linear-gradient(to bottom, #e0eeff 0%, #ffffff 100%);
  font-size: 1rem;
  color: #2c527f;
}

@media screen and (max-width: 991px) {
  .info-block-style-3 .info-block__text {
    font-size: .9rem;
  }
}

@media screen and (max-width: 767px) {
  .info-block-style-3 .info-block__text {
    font-size: 1rem;
  }
}

@media screen and (max-width: 575px) {
  .info-block-style-3 .info-block__text {
    max-height: 300px;
    font-size: .9rem;
  }
}

.info-block-style-3 .info-block__text::before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 6px;
  background-color: #18afd3;
}

.info-block-style-3 .info-block__text p {
  color: inherit;
}

.info-block-style-4 .info-block__img {
  order: 2;
}

.info-block-style-4 .info-block__text {
  order: 1;
  background: linear-gradient(to bottom, #e0eeff 0%, #ffffff 100%);
  font-size: 1rem;
  color: #2c527f;
  text-align: center;
}

@media screen and (max-width: 991px) {
  .info-block-style-4 .info-block__text {
    font-size: .9rem;
  }
}

@media screen and (max-width: 767px) {
  .info-block-style-4 .info-block__text {
    font-size: 1rem;
  }
}

@media screen and (max-width: 575px) {
  .info-block-style-4 .info-block__text {
    max-height: 300px;
    font-size: .9rem;
  }
}

.info-block-style-4 .info-block__text::before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 6px;
  background-color: #18afd3;
}

.info-block-style-4 .info-block__text p {
  color: inherit;
}

.info-block-style-5 {
  position: relative;
  padding-top: 28px;
}

.info-block-style-5::before {
  content: "";
  position: absolute;
  top: 0;
  left: 40px;
  width: 68px;
  height: 56px;
  background-image: url("../images/icon-quote.svg");
  background-repeat: no-repeat;
  z-index: 1;
}

@media screen and (max-width: 991px) {
  .info-block-style-5::before {
    left: 30px;
  }
}

.info-block-style-5 .info-block__img {
  order: 2;
}

.info-block-style-5 .info-block__text {
  order: 1;
  padding: 45px 40px;
  background-color: #f4f9ff;
  font-size: 1rem;
  color: #2c527f;
}

@media screen and (max-width: 991px) {
  .info-block-style-5 .info-block__text {
    padding: 45px 30px 35px;
    font-size: .9rem;
  }
}

@media screen and (max-width: 767px) {
  .info-block-style-5 .info-block__text {
    font-size: 1rem;
  }
}

@media screen and (max-width: 575px) {
  .info-block-style-5 .info-block__text {
    max-height: 310px;
    font-size: .9rem;
  }
}

.info-block-style-5 .info-block__text p {
  color: inherit;
}

.info-block-style-6 {
  position: relative;
  padding-top: 21px;
}

.info-block-style-6::before {
  content: "";
  position: absolute;
  top: 0;
  left: 70px;
  width: 50px;
  height: 41px;
  background-image: url("../images/icon-quote.svg");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
}

.info-block-style-6 .info-block__img {
  order: 2;
}

.info-block-style-6 .info-block__text {
  order: 1;
  padding: 45px 40px;
  background-color: #f4f9ff;
  font-size: 1rem;
  color: #2c527f;
  border: 3px solid #18afd3;
  border-right: 0;
}

@media screen and (max-width: 991px) {
  .info-block-style-6 .info-block__text {
    padding: 35px 30px 30px;
    font-size: .9rem;
  }
}

@media screen and (max-width: 767px) {
  .info-block-style-6 .info-block__text {
    border: 3px solid #18afd3;
    border-bottom: 0;
    font-size: 1rem;
  }
}

@media screen and (max-width: 575px) {
  .info-block-style-6 .info-block__text {
    max-height: 300px;
    padding: 35px 20px 20px;
    font-size: .9rem;
  }
}

.info-block-style-6 .info-block__text::before {
  position: absolute;
  top: -3px;
  left: 40px;
  width: 110px;
  height: 3px;
  background-color: #f4f9ff;
}

.info-block-style-6 .info-block__text p {
  color: inherit;
}

.info-block-style-7 {
  position: relative;
  padding-top: 44px;
}

.info-block-style-7::before {
  content: "";
  position: absolute;
  top: 0;
  left: 40px;
  width: 87px;
  height: 87px;
  border-radius: 100%;
  background-image: url("../images/icon-quote.svg");
  background-size: 38px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  box-shadow: 0px 8px 20px rgba(36, 109, 198, 0.1);
  z-index: 1;
}

.info-block-style-7 .info-block__img {
  order: 2;
}

.info-block-style-7 .info-block__text {
  order: 1;
  padding: 45px 40px;
  background-color: #f4f9ff;
  font-size: 1rem;
  color: #2c527f;
}

@media screen and (max-width: 991px) {
  .info-block-style-7 .info-block__text {
    padding: 45px 30px 30px;
    font-size: .9rem;
  }
}

@media screen and (max-width: 767px) {
  .info-block-style-7 .info-block__text {
    font-size: 1rem;
  }
}

@media screen and (max-width: 575px) {
  .info-block-style-7 .info-block__text {
    max-height: 300px;
    font-size: .9rem;
  }
}

.info-block-style-7 .info-block__text::before {
  position: absolute;
  top: -3px;
  left: 40px;
  width: 110px;
  height: 3px;
  background-color: #f4f9ff;
}

.info-block-style-7 .info-block__text p {
  color: inherit;
}

.info-block-style-8 {
  position: relative;
}

.info-block-style-8::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 248px;
  height: 227px;
  background-image: url("../images/icon-quote.svg");
  background-size: 248px;
  background-repeat: no-repeat;
  background-position: center;
  opacity: .07;
  z-index: 1;
}

.info-block-style-8 .info-block__img {
  order: 2;
}

.info-block-style-8 .info-block__text {
  order: 1;
  padding: 45px 40px;
  background-color: #fff;
  font-size: 1rem;
  color: #2c527f;
}

@media screen and (max-width: 991px) {
  .info-block-style-8 .info-block__text {
    padding: 35px 30px;
    font-size: .9rem;
  }
}

@media screen and (max-width: 767px) {
  .info-block-style-8 .info-block__text {
    font-size: 1rem;
  }
}

@media screen and (max-width: 575px) {
  .info-block-style-8 .info-block__text {
    max-height: 300px;
    font-size: .9rem;
  }
}

.info-block-style-8 .info-block__text p {
  color: inherit;
}

.info-block-style-9 {
  position: relative;
}

.info-block-style-9 .info-block__img {
  order: 2;
}

.info-block-style-9 .info-block__text {
  order: 1;
  padding: 45px 40px;
  background-color: #fff;
  font-size: 1rem;
  color: #2c527f;
  text-align: center;
}

@media screen and (max-width: 991px) {
  .info-block-style-9 .info-block__text {
    padding: 35px 30px 30px;
    font-size: .9rem;
  }
}

@media screen and (max-width: 767px) {
  .info-block-style-9 .info-block__text {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 575px) {
  .info-block-style-9 .info-block__text {
    padding: 30px 20px;
    max-height: 300px;
  }
}

.info-block-style-9 .info-block__text::before {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -50px;
  width: 100px;
  height: 4px;
  background-color: #18afd3;
}

.info-block-style-9 .info-block__text::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -50px;
  width: 100px;
  height: 4px;
  background-color: #18afd3;
}

.info-block-style-9 .info-block__text p {
  color: inherit;
}

.info-contact {
  display: flex;
  align-items: stretch;
  border-top: 3px solid #18afd3;
}

@media screen and (max-width: 991px) {
  .info-contact {
    flex-wrap: wrap;
  }
}

.info-contact > .info-contact {
  flex: 0 0 50%;
  max-width: 50%;
  flex-direction: column;
  padding: 20px;
  border: 0;
  background-color: rgba(24, 175, 211, 0.05);
}

@media screen and (max-width: 991px) {
  .info-contact > .info-contact {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.info-contact > .info-contact .info-contact__img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  min-width: 120px;
  height: 120px;
  min-height: 120px;
  background-color: #fff;
  border-radius: 100%;
  overflow: hidden;
}

.info-contact > .info-contact .info-contact__img > img {
  max-width: 100%;
}

.info-contact > .info-contact .info-contact__title {
  max-height: 53px;
  margin-bottom: 10px;
  overflow: hidden;
}

.info-contact > .info-contact .info-contact__title > a {
  color: #4c6575;
}

.info-contact > .info-contact .info-contact__title > a:hover, .info-contact > .info-contact .info-contact__title > a:focus {
  color: #18afd3;
  text-decoration: none;
}

.info-contact > .info-contact .info-contact__subtitle {
  max-height: 78px;
  overflow: hidden;
  color: #9a9a9a;
}

.info-contact > .info-contact .info-contact__adress,
.info-contact > .info-contact .info-contact__tel {
  max-height: 53px;
  overflow: hidden;
}

.info-contact > .info-contact .info-contact__adress + .info-contact__tel {
  margin-top: 10px;
}

.info-contact > .info-contact .icon {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  top: 3px;
  width: 18px;
  height: 24px;
  margin-right: 10px;
  font-size: 1.3rem;
  color: #18afd3;
}

.info-contact .info-contact-map {
  flex: 0 0 50%;
  max-width: 50%;
}

@media screen and (max-width: 991px) {
  .info-contact .info-contact-map {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.info-contact .info-contact-map .map {
  width: 100%;
  height: 100%;
  min-height: 290px;
}

.info-contact-style-1 {
  margin-top: 60px;
}

.info-contact-style-1 > .info-contact {
  padding: 40px;
}

@media screen and (max-width: 575px) {
  .info-contact-style-1 > .info-contact {
    align-items: center;
    padding: 40px 20px;
  }
  .info-contact-style-1 > .info-contact .info-contact__title,
  .info-contact-style-1 > .info-contact .info-contact__subtitle {
    text-align: center;
  }
}

.info-contact-style-1 > .info-contact .info-contact__img {
  position: relative;
  top: -103px;
  margin-bottom: -63px;
  box-shadow: 0 10px 20px rgba(24, 175, 211, 0.08);
}

.info-contact-style-1 > .info-contact .info-contact__adress {
  margin-top: 40px;
}

.info-contact-style-2 {
  flex-direction: column;
}

.info-contact-style-2 > .info-contact {
  flex: 0 0 100%;
  flex-direction: row;
  max-width: 100%;
}

@media screen and (max-width: 991px) {
  .info-contact-style-2 > .info-contact {
    justify-content: center;
    text-align: center;
  }
}

.info-contact-style-2 > .info-contact > div {
  width: 100%;
}

.info-contact-style-2 > .info-contact .info-contact__img {
  margin-right: 40px;
  box-shadow: 0 0 20px rgba(24, 175, 211, 0.08);
}

@media screen and (max-width: 991px) {
  .info-contact-style-2 > .info-contact .info-contact__img {
    margin-right: 0;
  }
}

.info-contact-style-2 > .info-contact .info-contact__title {
  margin-top: 20px;
}

.info-contact-style-2 > .info-contact .info-contact__adress {
  margin-top: 20px;
}

.info-contact-style-2 .info-contact-map {
  flex: 0 0 100%;
  max-width: 100%;
}

.gallery .gallery-item {
  position: relative;
  display: block;
  width: 100%;
}

.gallery .gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover";
}

.gallery .gallery-item .gallery-item-overlay {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  font-size: 3rem;
  color: rgba(255, 255, 255, 0);
  border: 4px solid #4c6575;
  z-index: 1;
  transition: color .3s ease;
}

.gallery .gallery-item .gallery-item-overlay span {
  transition: transform .2s ease;
  transform: scale(1.4);
}

.gallery .gallery-item .gallery-item-overlay::before, .gallery .gallery-item .gallery-item-overlay::after {
  content: "";
  position: absolute;
}

.gallery .gallery-item .gallery-item-overlay::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-color: #4c6575;
  transition: opacity .3s ease;
  z-index: -1;
}

.gallery .gallery-item .gallery-item-overlay::after {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: inset 0 0 0 1px rgba(76, 101, 117, 0), inset 0 0 0 1px rgba(255, 255, 255, 0);
  transition: box-shadow .2s ease;
  transition-delay: .15s;
  z-index: 1;
}

.gallery .gallery-item .gallery-item-overlay:hover {
  color: white;
}

.gallery .gallery-item .gallery-item-overlay:hover span {
  transform: scale(1);
}

.gallery .gallery-item .gallery-item-overlay:hover::before {
  opacity: .95;
}

.gallery .gallery-item .gallery-item-overlay:hover::after {
  box-shadow: inset 0 0 0 8px #4c6575, inset 0 0 0 10px #fff;
}

.box,
.box-1 {
  position: relative;
  display: block;
  z-index: 1;
}

.box-1 {
  padding: 55px 0;
}

.box-2 {
  padding: 20px 0;
}

.slider .swiper-slide {
  height: auto;
}

.slider .swiper-button-next,
.slider .swiper-button-prev {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 82px;
  height: 82px;
  margin-top: -41px;
  font-size: 2rem;
  color: #fff;
  background: none;
  background-color: #18afd3;
  box-shadow: inset 0 0 0 1px rgba(24, 175, 211, 0), inset 0 0 0 1px rgba(255, 255, 255, 0);
  transition: background-color .2s ease, box-shadow .2s ease;
}

.slider .swiper-button-next:hover,
.slider .swiper-button-prev:hover {
  box-shadow: inset 0 0 0 5px #18afd3, inset 0 0 0 7px #fff;
}

@media screen and (max-width: 575px) {
  .slider .swiper-button-next,
  .slider .swiper-button-prev {
    width: 60px;
    height: 60px;
    top: 150px;
    margin-top: 0;
  }
}

.slider .swiper-button-next {
  right: 0;
}

.slider .swiper-button-prev {
  left: 0;
}

@media screen and (max-width: 767px) {
  .slider-arrows-clone {
    padding-top: 60px;
  }
}

.slider-arrows-clone .swiper-button-next,
.slider-arrows-clone .swiper-button-prev {
  visibility: hidden;
  opacity: 0;
  z-index: -1;
}

.slider-arrows-clone + .slider-buttons {
  position: absolute;
  top: 55px;
  left: 0;
  width: 100%;
  height: 0;
  z-index: 0;
}

@media screen and (max-width: 767px) {
  .slider-arrows-clone + .slider-buttons {
    top: 120px;
  }
}

.slider-arrows-clone + .slider-buttons .container {
  display: flex;
  justify-content: flex-end;
  height: 0;
}

.slider-arrows-clone + .slider-buttons .swiper-button-next,
.slider-arrows-clone + .slider-buttons .swiper-button-prev {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  width: 60px;
  height: 60px;
  margin-top: 0;
  background: none;
  border-radius: 100%;
  color: #18afd3;
  font-size: 1.5rem;
  transition: box-shadow .3s ease;
  z-index: 1;
}

.slider-arrows-clone + .slider-buttons .swiper-button-next::before, .slider-arrows-clone + .slider-buttons .swiper-button-next::after,
.slider-arrows-clone + .slider-buttons .swiper-button-prev::before,
.slider-arrows-clone + .slider-buttons .swiper-button-prev::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 100%;
}

.slider-arrows-clone + .slider-buttons .swiper-button-next::before,
.slider-arrows-clone + .slider-buttons .swiper-button-prev::before {
  box-shadow: inset 0 0 0 1px #e5e5e5;
  transition: transform .2s ease, box-shadow .2s ease;
  transform: scale(1);
}

.slider-arrows-clone + .slider-buttons .swiper-button-next::after,
.slider-arrows-clone + .slider-buttons .swiper-button-prev::after {
  box-shadow: inset 0 0 0 4px #4c6575;
  opacity: 0;
  transition: transform .2s ease, opacity .25s ease;
  transition-delay: .2s;
  transform: scale(1.2);
}

.slider-arrows-clone + .slider-buttons .swiper-button-next:hover::before,
.slider-arrows-clone + .slider-buttons .swiper-button-prev:hover::before {
  transform: scale(0.8);
}

.slider-arrows-clone + .slider-buttons .swiper-button-next:hover::after,
.slider-arrows-clone + .slider-buttons .swiper-button-prev:hover::after {
  opacity: 1;
  transform: scale(1);
}

.slider-arrows-clone + .slider-buttons .swiper-button-prev {
  margin-right: 20px;
}

.slider-specialists + .slider-buttons .swiper-button-next,
.slider-specialists + .slider-buttons .swiper-button-prev {
  color: #fff;
}

.slider-specialists + .slider-buttons .swiper-button-next::before,
.slider-specialists + .slider-buttons .swiper-button-prev::before {
  box-shadow: inset 0 0 0 1px #e5e5e5;
}

.slider-specialists + .slider-buttons .swiper-button-next::after,
.slider-specialists + .slider-buttons .swiper-button-prev::after {
  box-shadow: inset 0 0 0 4px #fff;
}

.slider-main .swiper-wrapper {
  align-items: stretch;
}

.slider-main .swiper-wrapper .swiper-slide {
  padding: 90px 0 180px;
}

.slider-main .swiper-wrapper .swiper-slide .container {
  position: relative;
}

.slider-main .swiper-wrapper .swiper-slide .slider-title {
  margin-bottom: 20px;
  line-height: normal;
}

.slider-main .swiper-wrapper .swiper-slide .slider-text,
.slider-main .swiper-wrapper .swiper-slide .slider-phone {
  font-size: 1.4rem;
}

.slider-main .swiper-wrapper .swiper-slide .slider-phone {
  margin-top: 10px;
  font-weight: 500;
}

.slider-main .swiper-wrapper .swiper-slide .slider-phone a {
  color: inherit;
}

.slider-main .swiper-wrapper .swiper-slide .slider-phone a:hover, .slider-main .swiper-wrapper .swiper-slide .slider-phone a:focus {
  color: inherit;
}

.slider-reviews .swiper-slide {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.slider-reviews .swiper-slide a {
  height: 100%;
}

.slider-reviews .swiper-slide img {
  max-width: 100%;
}

.slider-docs .swiper-slide {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.slider-docs .swiper-slide a {
  height: 100%;
}

.slider-docs .swiper-slide img {
  max-width: 100%;
}

.slider-gallery {
  padding-bottom: 60px;
}

.slider-gallery .swiper-pagination {
  bottom: 0;
}

.slider-gallery .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: #18afd3;
  opacity: .4;
  transition: opacity .2s ease;
}

.slider-gallery .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
}

.slider-gallery .swiper-button-next,
.slider-gallery .swiper-button-prev {
  top: 50%;
  margin-top: -60px;
}

@media screen and (max-width: 575px) {
  .slider-gallery .swiper-button-next,
  .slider-gallery .swiper-button-prev {
    margin-top: -60px;
  }
}

.slider-gallery .swiper-slide {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.slider-gallery .swiper-slide .container,
.slider-gallery .swiper-slide .row {
  height: 100%;
}

.slider-gallery .swiper-slide a {
  height: 100%;
}

.slider-gallery .swiper-slide img {
  max-width: 100%;
}

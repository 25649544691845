html {
    height: 100%;
}

body {
    position: relative;
    height: 100%;
    padding: 0;
    margin: 0;
    background: $bg_body;

    &::-webkit-scrollbar{
    	width: $scrollbar-width;
    	background-color: $scrollbar-color-fon;
    }
    &::-webkit-scrollbar-thumb{
    	background-color: $scrollbar-color;
    }
    &.fancybox-active {
        margin-right: $scrollbar-width !important;
    }
}

.img {
    max-width: 100%;
}

// Fix IE Icons
.icon {
    display: inline-block;
    text-decoration: none;
    outline: none;
    background-size: cover;
    background-repeat: no-repeat;

    &.icon-keypad {
        width: 32px;
        height: 32px;
        background-image: url("../images/icon-keypad.svg");
    }
    &.icon-time {
        width: 32px;
        height: 32px;
        background-image: url("../images/icon-time.svg");
    }
    &.icon-feature-one {
        width: 117px;
        height: 100px;
        background-image: url("../images/icon-feature-one.svg");
    }
    &.icon-feature-two {
        width: 87px;
        height: 106px;
        background-image: url("../images/icon-feature-two.svg");
    }
    &.icon-feature-three {
        width: 102px;
        height: 102px;
        background-image: url("../images/icon-feature-three.svg");
    }
    &.icon-feature-1 {
        width: 65px;
        height: 76px;
        background-image: url("../images/icon-feature-1.svg");
    }
    &.icon-feature-2 {
        width: 69px;
        height: 68px;
        background-image: url("../images/icon-feature-2.svg");
    }
    &.icon-feature-3 {
        width: 55px;
        height: 64px;
        background-image: url("../images/icon-feature-3.svg");
    }
    &.icon-feature-4 {
        width: 74px;
        height: 63px;
        background-image: url("../images/icon-feature-4.svg");
    }
    &.icon-feature-5 {
        width: 67px;
        height: 67px;
        background-image: url("../images/icon-feature-5.svg");
    }
    &.icon-feature-6 {
        width: 70px;
        height: 59px;
        background-image: url("../images/icon-feature-6.svg");
    }
    &.icon-feature-7 {
        width: 55px;
        height: 67px;
        background-image: url("../images/icon-feature-7.svg");
    }
    &.icon-feature-8 {
        width: 46px;
        height: 78px;
        background-image: url("../images/icon-feature-8.svg");
    }
    &.icon-feature-9 {
        width: 73px;
        height: 64px;
        background-image: url("../images/icon-feature-9.svg");
    }
    &.icon-feature-10 {
        width: 69px;
        height: 69px;
        background-image: url("../images/icon-feature-10.svg");
    }
    &.icon-feature-11 {
        width: 62px;
        height: 73px;
        background-image: url("../images/icon-feature-11.svg");
    }
    &.icon-feature-12 {
        width: 69px;
        height: 54px;
        background-image: url("../images/icon-feature-12.svg");
    }
}

// Main Content
.main {
    position: relative;
    width: 100%;
    min-height: 100%;
    overflow: hidden;

    &__content {
        position: relative;
        z-index: 1;
    }
}

// bg image
.bg-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: 50% 50%;
    background-size: cover;
    z-index: -1;

    > * {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-position: 50% 50%;
        background-size: cover;
    }
    &.bg-pattern {
        background-size: auto;

        > * {
            background-size: auto;
        }
    }
}

// Object Fit
.bg-image-parallax,
.bg-image {
    img {
        width: 100%;
        height: 100%;
        // stylelint-disable-next-line
        font-family: "object-fit: cover";
        object-fit: cover;
    }
}

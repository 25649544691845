.gallery {
    .gallery-item {
        position: relative;
        display: block;
        width: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            font-family: "object-fit: cover";
        }

        .gallery-item-overlay {
            position: absolute;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow: hidden;
            font-size: 3rem;
            color: rgba(255, 255, 255, 0);
            border: 4px solid $color-main-3;
            z-index: 1;
            transition: color .3s ease;

            span {
                transition: transform .2s ease;
                transform: scale(1.4);
            }
            &::before,
            &::after {
                content: "";
                position: absolute;
            }
            &::before {
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                opacity: 0;
                background-color: $color-main-3;
                transition: opacity .3s ease;
                z-index: -1;
            }
            &::after {
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                box-shadow: inset 0 0 0 1px rgba($color-main-3, 0),
                            inset 0 0 0 1px rgba(#fff, 0);
                transition: box-shadow .2s ease;
                transition-delay: .15s;
                z-index: 1;
            }
            &:hover {
                color: rgba(255, 255, 255, 1);

                span {
                    transform: scale(1);
                }

                &::before {
                    opacity: .95;
                }
                &::after {
                    box-shadow: inset 0 0 0 8px $color-main-3,
                                inset 0 0 0 10px #fff;
                }
            }
        }
    }
}

// Btn main
.btn {
    border: 0;
    border-radius: 0;
    color: $color_main_3;
    text-decoration: none;

    &:hover,
    &:focus {
        color: $color_main_3;
        text-decoration: none;
        box-shadow: none;
    }
}

.btn-round {
    border-radius: 40px;
}

.btn-main {
    display: inline-block;
    padding: 12px 50px;
    background-color: $color-main-1;
    box-shadow: inset 0 0 0 1px rgba($color-main-1, 0),
                inset 0 0 0 1px rgba(#fff, 0);
    font-weight: 300;
    color: #fff;
    transition: box-shadow .2s ease;

    &:hover {
        color: #fff;
        box-shadow: inset 0 0 0 5px $color-main-1,
                    inset 0 0 0 6px #fff;
    }
    &:focus {
        color: #fff;
    }
}

.btn-main-2 {
    background-color: $color-main-2;
    box-shadow: inset 0 0 0 1px rgba($color-main-2, 0),
                inset 0 0 0 1px rgba(#fff, 0);

    &:hover {
        color: #fff;
        box-shadow: inset 0 0 0 5px $color-main-2,
                    inset 0 0 0 6px #fff;
    }
}

.social-icons {
    padding: 0;
    margin: 0;
    font-size: 0;
    list-style: none;

    > li {
        display: inline-block;

        + li {
            margin-left: 10px;
        }

        > * {
            cursor: pointer;
            display: block;
            width: 28px;
            height: 28px;
            font-size: .9rem;
            line-height: 2em;
            color: #fff;
            text-decoration: none;
            text-align: center;
            transition: background-color .2s ease;

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }

    // Sizes
    &_lg > li > * {
        width: 42px;
        height: 42px;
        font-size: 1.3rem;
    }

    // Round
    &_round > li > * {
        border-radius: 100%;
    }



    @each $name, $color in $colors_social {
        > li > .social-icons__#{$name} {
            background-color: $color;

            &:hover {
                background-color: rgba($color, .8);
            }
        }
    }
}

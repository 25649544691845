.mfp-wrap {
    &::-webkit-scrollbar{
    	width: $scrollbar-width;
    	background-color: $scrollbar-color-fon;
    }
    &::-webkit-scrollbar-thumb{
    	background-color: $scrollbar-color;
    }
}

.modal {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;

    .container {
        position: relative;
        z-index: 1;
    }

    .mfp-close {
        position: absolute;
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-top: 40px;
        padding-right: 40px;
        padding-bottom: 300%;
        color: #fff;
        z-index: 0;

        @media screen and #{$media_sm} {
            align-items: center;
            justify-content: center;
            left: auto;
            bottom: auto;
            width: 50px;
            height: 50px;
            z-index: 1;
        }
    }

    .modal-content {
        padding: 0 20px;
        border-radius: 0;
    }
}

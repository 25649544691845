.breadcrumbs + .header {
    margin-top: 40px;
}
.header {
    position: relative;
    display: block;
    z-index: 1;

    &-image {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        min-height: 420px;
        padding: 40px;

        @media screen and #{$media_md} {
            min-height: 320px;
        }
        @media screen and #{$media_md} {
            min-height: 300px;
            padding: 20px;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(to top, rgba(0, 0, 0, .7) 10%, rgba(0, 0, 0, 0) 40%);
            z-index: 0;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 0;
        }

        &__title,
        &__views,
        &__tags {
            position: relative;
            z-index: 1;
        }

        &__title {
            display: block;
            margin-bottom: 20px;
            color: #fff;
            font-weight: 700;

            @media screen and #{$media_md} {
                font-size: 2.3rem;
            }
            @media screen and #{$media_sm} {
                font-size: 2.2rem;
            }
        }
        &__views {
            display: flex;
            color: #fff;

            .icon {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                bottom: 0px;
                height: 24px;
                margin-right: 8px;
                font-size: 1.5rem;
            }
        }
        &__tags {
            display: flex;
            align-items: flex-start;
            color: #fff;

            .icon {
                display: inline-block;
                margin-right: 5px;
                margin-left: 10px;

                @media screen and #{$media_sm} {
                    margin-left: 0;
                }
            }

            &-list {
                display: inline-block;
                margin: 0;
                padding: 0;

                li {
                    display: inline-block;
                    margin: 0 4px 0 0;
                    padding: 0;
                    list-style: none;

                    &::after {
                        content: "/";
                        display: inline-block;
                        margin-left: 3px;
                        color: $color_grey_3;
                    }

                    &:last-child {
                        margin-right: 0;

                        &::after {
                            content: "";
                        }
                    }

                    a {
                        position: relative;
                        display: inline-block;
                        top: -1px;
                        color: #fff;

                        &:hover,
                        &:focus {
                            color: #fff;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}

.navbar {
    position: relative;
    display: flex;
    align-items: center;
    padding: 30px 0;
    font-family: $font_navbar;
    background-color: #fff;
    z-index: 10;

    .navbar-close {
        display: none;
    }

    @media screen and #{$media_lg} {
        padding: 20px 0;

        .navbar-close {
            position: absolute;
            display: block;
            top: 109px;
            left: 0;
            width: 100vw;
            height: 100vh;
            cursor: pointer;
            background-color: rgba($color_main_3, .8);
            opacity: 0;
            visibility: hidden;
            transition: visibility .2s ease, opacity .2s ease;
            z-index: 9;

            &.active {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &.is_stuck {
        box-shadow: 0 1px 0 0 rgba(0, 0, 0, .04);
    }

    > [class*="container"] {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        z-index: 10;
    }

    .navbar-burger {
        position: absolute;
        display: none;
        top: 50%;
        right: 40px;
        width: 24px;
        height: 16px;
        margin-top: -8px;
        cursor: pointer;

        > span {
            display: block;
            width: 100%;
            height: 2px;
            background-color: $color_main_3;
        }

        @media screen and #{$media_lg} {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }
    }

    .navbar-logo {
        width: 200px;
        min-width: 200px;

        a {
            display: block;
        }
        img {
            max-width: 100%;
        }
    }
    .navbar-content {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;

        @media screen and #{$media_lg} {
            position: absolute;
            top: 0;
            right: 0;
            flex-direction: column;
            justify-content: center;
            height: 100vh;
            padding: 40px;
            background-color: #fff;
            box-shadow: -1px 0 0 0 rgba(0, 0, 0, .04);
            transition: transform .4s ease;
            transform: translateX(100%);
            z-index: 11;

            &.active {
                transform: translateX(0);
            }
        }

        > li {
            display: inline-block;
            padding: 0;
            margin: 0;
            font-size: .74rem;
            list-style: none;

            a {
                display: block;
                padding: 12px 10px;
                font-weight: 500;
                color: $color_grey_2;
                text-transform: uppercase;
                transition: color .2s ease;

                @media screen and #{$media_xl} {
                    padding: 12px 6px;
                }
                @media screen and #{$media_lg} {
                    padding: 10px 6px;
                    font-size: 1rem;
                }

                &:hover,
                &:focus {
                    color: $color_main_1;
                    text-decoration: none;
                }
            }
        }
    }
}
